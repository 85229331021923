import React, { useEffect, useState } from 'react';
import useBem from '@indicia/use-bem';
import { Trans, useTranslation } from 'react-i18next';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';

import Dialog from '../../shared/components/dialog/Dialog';
import { useResultContext } from './store/ResultContext';
import { useSurveyContext } from '../survey/store/SurveyContext';
import SurveyQuestionType from '../../shared/models/survey/SurveyQuestionType';
import SurveyQuestionMultipleChoice from '../../shared/models/survey/SurveyQuestionMultipleChoice';
import { postAnswers } from '../survey/data/SurveyService';
import { Result, SurveyQuestionCategory } from './types';
import Loader from '../../shared/components/loader/Loader';
import useSurvey from '../survey/hooks/useSurvey';
import RenderDirection from '../../shared/models/survey/RenderDirection';
import Button from '../../shared/components/button/Button';
import ButtonTheme from '../../shared/components/button/enums/ButtonTheme';
import Statistics from './components/statistics/Statistics';
import Category from './components/category/Category';
import SurveyAnswer from '../../shared/models/survey/SurveyAnswer';
import BackgroundContainer from '../../shared/components/background-container/background-container';
import Icon from '../../shared/components/button/enums/Icon';
import IconPosition from '../../shared/components/button/enums/IconPosition';

import './DashboardPage.scss';

const DashboardPage = () => {
  const { t } = useTranslation();
  const { bemClassName, bemClassNames } = useBem('dashboard');
  const { bemClassName: bemClassNameTop, bemClassNames: bemClassNamesTop } = useBem('top-wrapper');
  const { result, setResult } = useResultContext();
  const {
    questionAnswers,
    activationCode,
    email,
    setQuestionAnswer,
    resetAnswers,
    setEmailAddress,
    getBackupQuestionAnswers,
    setSurvey,
    sessionKey,
  } = useSurveyContext();
  const [showDialog, setShowDialog] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [mail, setMail] = useState('');
  const [showMailDialog, setShowMailDialog] = useState(false);

  useEffect(() => {
    const isSticky = () => {
      const { scrollY, innerWidth } = window;
      const scrollTop = scrollY;
      // Check if the user is scrolling down
      // If the user is scrolling down, the header should be sticky
      // Threshold is 70 for desktop and 275 for mobile
      const threshold = innerWidth > 1366 ? 70 : 275;
      setSticky(scrollTop > threshold);
    };
    window.addEventListener('scroll', isSticky);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const surveyId = 1;
  const { isLoading, data } = useSurvey(surveyId, activationCode);

  if (isLoading) {
    return <Loader />;
  }

  const { specification } = data!;
  setSurvey(specification);

  const { echteLeeftijd, inputLeeftijd, topImprovements, topCompliments } = result;
  const variableQuestions = specification!.questions.filter((question) => question.variability === 'Variable');
  const categorizedQuestions: SurveyQuestionCategory[] = [];

  variableQuestions.forEach((question) => {
    const { questionType, group } = question;

    let renderDirection = RenderDirection.Horizontal;

    if (questionType === SurveyQuestionType.MULTIPLE_CHOICE) {
      const { renderDirection: questionRenderDirection } = question as SurveyQuestionMultipleChoice;
      if (questionRenderDirection === RenderDirection.Vertical) {
        renderDirection = RenderDirection.Vertical;
      }
    }

    const category = categorizedQuestions.find((c) => c.category === group);

    if (category) {
      const { renderDirection: categoryRenderDirection } = category;

      if (categoryRenderDirection === RenderDirection.Horizontal && renderDirection === RenderDirection.Vertical) {
        category.renderDirection = RenderDirection.Vertical;
      }

      category.questions.push(question);
    } else {
      categorizedQuestions.push({
        category: question.group,
        questions: [question],
        renderDirection,
      });
    }
  });

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const toggleMailDialog = () => {
    setShowMailDialog(!showMailDialog);
  };

  const submitMail = () => {
    setEmailAddress(mail);
    const answers = getBackupQuestionAnswers();
    postAnswers(surveyId, answers, activationCode, true, mail, sessionKey).then(() => {
      toggleMailDialog();
    });
  };

  const recalculate = () => {
    postAnswers(surveyId, questionAnswers, activationCode, false).then((r: any) => {
      setResult(r.data as Result);
    });
  };

  const toggleReset = () => {
    resetAnswers();
    const answers = getBackupQuestionAnswers();
    postAnswers(surveyId, answers, activationCode, false).then((r: any) => {
      setResult(r.data as Result);
    });
  };

  const onSetNewAnswer = (questionAnswer: SurveyAnswer) => {
    setQuestionAnswer(questionAnswer);
    recalculate();
  };

  return (
    <div className={bemClassNames([bemClassName(), 'background-image'])}>
      <a
        className={bemClassNames([bemClassName('feedback-button'), bemClassName('feedback-button', 'sticky', sticky)])}
        href={window.__RUNTIME_CONFIG__.FEEDBACK_URL}
        target="_blank"
        rel="noreferrer"
        id="feedback-button"
      >
        <span>{t('dashboard.feedback')}</span>
      </a>
      <BackgroundContainer type="dashboard-header">
        <Statistics
          age={inputLeeftijd}
          realAge={echteLeeftijd}
          onInfoClick={toggleDialog}
          compliments={topCompliments}
          improvements={topImprovements}
          sticky={sticky}
        />
        <div className={bemClassNamesTop([bemClassNameTop(), bemClassNameTop(null, 'sticky', sticky)])}>
          <div
            className={bemClassNames([
              bemClassName('habits-container'),
              bemClassName('habits-container', 'action-bar'),
            ])}
          >
            <h2>{t('dashboard.subtitle')}</h2>
            <Button
              id="reset-button"
              onClick={toggleReset}
              icon={Icon.renew}
              iconPosition={IconPosition.LEFT}
              iconWidth="24px"
              iconHeight="24px"
              label={t('dashboard.reset')!}
              theme={ButtonTheme.TEXT}
            />
          </div>
          {email === undefined ||
            (email === '' && (
              <div className="mail-button-container">
                <Button
                  id="mail-button"
                  onClick={toggleMailDialog}
                  icon={Icon.email}
                  iconPosition={IconPosition.LEFT}
                  iconWidth="24px"
                  iconHeight="24px"
                  label={t('dashboard.mail-me')!}
                  theme={ButtonTheme.TEXT}
                />
              </div>
            ))}
        </div>
      </BackgroundContainer>
      <div className={bemClassName('habits-list')}>
        {categorizedQuestions.map((category) => (
          <Category
            key={category.category}
            category={category}
            setQuestionAnswer={onSetNewAnswer}
          />
        ))}
      </div>

      {showDialog && (
        <Dialog
          onClose={toggleDialog}
          title={t('dashboard.dialog.title')!}
          content={
            <Trans
              i18nKey="dashboard.dialog.content"
              components={{ 1: <br /> }}
            />
          }
        />
      )}

      {showMailDialog && (
        <Dialog
          onClose={toggleMailDialog}
          title={t('dashboard.mail-dialog.title')!}
          content={
            <>
              <Trans
                i18nKey="dashboard.mail-dialog.content"
                components={{ 1: <br /> }}
              />
              <div className="mail-dialog__input">
                <Input
                  type="text"
                  value={mail}
                  placeholder={t('dashboard.mail-dialog.input')!}
                  onChange={(e: InputChangeEvent) => {
                    const {
                      target: { value },
                    } = e;
                    setMail(value as string);
                  }}
                />
              </div>
            </>
          }
          actions={
            <>
              <Button
                onClick={toggleMailDialog}
                label={t('dashboard.mail-dialog.cancel')!}
                theme={ButtonTheme.TEXT}
              />
              <Button
                onClick={submitMail}
                label={t('dashboard.mail-dialog.submit')!}
              />
            </>
          }
        />
      )}
    </div>
  );
};

export default DashboardPage;
