enum Icon {
  alcohol = 'alcohol',
  beweging = 'beweging',
  bloeddruk = 'bloeddruk',
  arrowLeft = 'arrow-left',
  arrowRight = 'arrow-right',
  chevronRight = 'chevron-right',
  chevron = 'chevron',
  gewicht = 'gewicht',
  koffie = 'koffie',
  roken = 'roken',
  slapen = 'slapen',
  stress = 'stress',
  vitalewaarden = 'vitalewaarden',
  voeding = 'voeding',
  renew = 'autorenew',
  email = 'email',
  suggestion = 'suggestion',
  cholesterol = 'cholesterol',
}

export default Icon;
