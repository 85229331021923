import { FC } from 'react';
import useBem from '@indicia/use-bem';

import { SolutionsContainerType } from '../../types';
import IconRender from '../../../../shared/components/icon/IconRender';
import Icon from '../../../../shared/components/button/enums/Icon';

import './theme-foldout-header.scss';

const ThemeZkHelp: FC<SolutionsContainerType & { onClick: () => void; active: boolean }> = ({
  active,
  title,
  description,
  linkText,
  onClick,
}) => {
  const { bemClassName, bemClassNames } = useBem('theme-foldout-header');

  return (
    <div className={bemClassName()}>
      <div className="container">
        <div className={bemClassName('content')}>
          <h3>{title}</h3>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: description! }} />
          <button
            type="button"
            className={bemClassNames([bemClassName('button'), bemClassName('button', 'active', active)])}
            onClick={onClick}
          >
            {linkText}
            <IconRender
              icon={Icon.chevron}
              color="currentColor"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThemeZkHelp;
