import { useState } from 'react';
import { motion } from 'framer-motion';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import LoaderContainer from '../../shared/components/loader/LoaderContainer';
import IconRender from '../../shared/components/icon/IconRender';
import ThemeHeader from './components/theme-header/theme-header';
import ThemeTips from './components/theme-tips/theme-tips';
import useTheme from './hooks/use-theme';
import ThemeEmployerHelp from './components/theme-employer-help/theme-employer-help';
import ThemeZkHelp from './components/theme-zk-help/theme-zk-help';
import Icon from '../../shared/components/button/enums/Icon';
import BackgroundContainer from '../../shared/components/background-container/background-container';
import ThemeFoldoutHeader from './components/theme-foldout-header/theme-foldout-header';

import './theme-page.scss';

const ThemePage = () => {
  const { t } = useTranslation();
  const { theme } = useParams();
  const navigate = useNavigate();
  const key = localStorage.getItem('gezondleven-activation-code');
  const { bemClassName, bemClassNames } = useBem('theme-page');
  const { data, isLoading } = useTheme(key ?? '', theme!);
  const [foldoutActive, setFoldoutActive] = useState<boolean>(false);
  if (!key || !theme) {
    navigate('/');
  }

  if (isLoading) {
    return <LoaderContainer text={t('theme.loading')} />;
  }

  const { showProductsImmediately, header, tipsContainer, employerSolutions, insurerHeader, offeredSolutions } = data!;

  return (
    <div className={bemClassName()}>
      <BackgroundContainer type="beige">
        <div className={bemClassNames([bemClassName('breadcrumbs'), 'container'])}>
          <NavLink
            to="/dashboard"
            className={bemClassName('breadcrumb')}
          >
            {t('theme.breadcrumb-root')}
          </NavLink>
          <IconRender
            icon={Icon.chevronRight}
            color="var(--zk-blue)"
            height="24px"
            width="24px"
          />
          <span className={bemClassName('breadcrumb')}>{theme}</span>
        </div>
      </BackgroundContainer>
      <ThemeHeader {...header} />
      <div className={bemClassName('content')}>
        <ThemeTips {...tipsContainer} />
        {employerSolutions && <ThemeEmployerHelp {...employerSolutions} />}
        {showProductsImmediately && (
          <ThemeZkHelp
            {...offeredSolutions}
            showProductsImmediately={showProductsImmediately}
          />
        )}
        {!showProductsImmediately && (
          <>
            <ThemeFoldoutHeader
              {...insurerHeader}
              onClick={() => setFoldoutActive(!foldoutActive)}
              active={foldoutActive}
            />
            <motion.div
              className={bemClassName('foldout')}
              initial={{ height: 0, overflow: 'hidden' }}
              animate={{ height: foldoutActive ? 'auto' : 0, overflow: foldoutActive ? 'visible' : 'hidden' }}
              transition={{ duration: 0.3 }}
              onUpdate={() => {
                if (foldoutActive) {
                  document
                    .querySelector(`.${bemClassName('foldout')}`)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
              }}
            >
              <ThemeZkHelp
                {...offeredSolutions}
                showProductsImmediately={showProductsImmediately}
              />
            </motion.div>
          </>
        )}
      </div>
      <BackgroundContainer type="white">
        <div className="container">
          <div className={bemClassName('footer')}>{t('theme.footer')}</div>
        </div>
      </BackgroundContainer>
    </div>
  );
};

export default ThemePage;
